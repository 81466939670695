<template>
    <admin-dashboard-layout>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="countries"
            :items-per-page="30"
            class="elevation-1"
            @click:row="handleClickRow"
        >
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at ? moment(item.created_at).format('LLL') : '' }}
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import Country from "../../../models/admin/Country";

    /* eslint-disable */
    export default {
        name: "prohibited-words-index",
        components: {
            AdminDashboardLayout,
        },
        data: function () {
            return {
                loading: false,
                countries: [],
                headers: [
                    {
                        text: '# ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    { text: 'Code', value: 'code' },
                    { text: 'Name', value: 'name' },
                    { text: 'Active', value: 'is_active' },
                    { text: 'Created at', value: 'created_at' },
                ],
            }
        },
        methods: {
            handleClickRow(country) {
                this.$router.push({name: 'admin.countries.show', params: {id: country.id}})
            }
        },
        async mounted() {
            this.loading = true
            this.countries = await Country.get()
            this.loading = false
        },
    }
</script>

<style scoped>

</style>
